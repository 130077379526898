import { Injectable } from '@angular/core';
import { LoadingController, ToastController, AlertController } from '@ionic/angular';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})

export class FunctionsService {
  isLoading: boolean = false;

  constructor(
    private loadingCtrl: LoadingController,
    private toastCtrl: ToastController,
    private platform: Platform,
    private alertCtrl:AlertController
  ) {}

  async showLoading(message = '') {
    if(this.isLoading) return
    this.isLoading = true;
    await this.loadingCtrl
      .create({
        message: message,
      })
      .then((loading) => {
        loading.present().then((_) => {
          if (!this.isLoading) loading.dismiss();
        });
      });
  }

  async dismissLoading() {
    this.isLoading = false;
    await this.loadingCtrl.dismiss()
      .catch(err => this.loadingCtrl.dismiss())
  }

  async presentToast(message:string) {
    let toast = await this.toastCtrl.create({
      message,
      duration: 3000,
      position: 'bottom',
      cssClass: 'ion-text-center',
      buttons: [
        {
          icon: 'close',
          role:"cancel"
        },
      ],
    });
    await toast.present();
  }


  async alert(header:string,message:string) {
    const alert = await this.alertCtrl.create({
      header:header || "",
      message:message || "",
      mode:"ios",
      buttons:[
        {
          text:"موافق",
          role:"confirm",
        },
        {
          text:"الغاء",
          role:"cancel",
        }
      ]
    })

    await alert.present();
    const data = await alert.onDidDismiss()
    return data?.role == 'confirm'
  }



}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  branches:any[] = []
  private param: any = {};
  private baseURL: string = 'https://babelon.appsiraq.com/api';
  // private baseURL: string = 'http://localhost:5088/api';

  constructor(private http: HttpClient,) {}

  setParams(body: any) {
    this.param = body;
  }


  get params() {
    return this.param;
  }


  getData(endPoint: string) {
    return this.http.get(this.baseURL + endPoint).pipe(take(1));
  }

  postData(endPoint: string, body: any) {
    return this.http.post(this.baseURL + endPoint, body).pipe(take(1));
  }

  updateData(endPoint: string, body: any) {
    return this.http.put(this.baseURL + endPoint, body).pipe(take(1));
  }

  deleteDate(endPoint: string) {
    return this.http.delete(this.baseURL + endPoint).pipe(take(1));
  }

  setApi(api:string) {
    this.baseURL = api.includes("http") ? api :  `http://${api}` ;
  }

  get api() {
    return this.baseURL
  }



}

import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'menu',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'category',
    loadChildren: () => import('./pages/category/category.module').then( m => m.CategoryPageModule)
  },
  {
    path: 'product',
    loadChildren: () => import('./pages/product/product.module').then( m => m.ProductPageModule)
  },

  {
    path: 'add-category',
    loadChildren: () => import('./pages/add-category/add-category.module').then( m => m.AddCategoryPageModule)
  },
  {
    path: 'add-product',
    loadChildren: () => import('./pages/add-product/add-product.module').then( m => m.AddProductPageModule)
  },
  {
    path: 'branches',
    loadChildren: () => import('./pages/branches/branches.module').then( m => m.BranchesPageModule)
  },
  {
    path: 'add-branch',
    loadChildren: () => import('./pages/add-branch/add-branch.module').then( m => m.AddBranchPageModule)
  },
  {
    path: 'discount',
    loadChildren: () => import('./pages/discount/discount.module').then( m => m.DiscountPageModule)
  },
  {
    path: 'add-discount',
    loadChildren: () => import('./pages/add-discount/add-discount.module').then( m => m.AddDiscountPageModule)
  },
  {
    path: 'add-item',
    loadChildren: () => import('./pages/add-item/add-item.module').then( m => m.AddItemPageModule)
  },
  {
    path: 'item',
    loadChildren: () => import('./pages/item/item.module').then( m => m.ItemPageModule)
  },
  {
    path: 'coupon',
    loadChildren: () => import('./pages/coupon/coupon.module').then( m => m.CouponPageModule)
  },
  {
    path: 'add-coupon',
    loadChildren: () => import('./pages/add-coupon/add-coupon.module').then( m => m.AddCouponPageModule)
  },
  {
    path: 'orders',
    loadChildren: () => import('./pages/orders/orders.module').then( m => m.OrdersPageModule)
  },
  {
    path: 'users',
    loadChildren: () => import('./pages/users/users.module').then( m => m.UsersPageModule)
  },
  {
    path: 'slider',
    loadChildren: () => import('./pages/slider/slider.module').then( m => m.SliderPageModule)
  },
  {
    path: 'add-slider',
    loadChildren: () => import('./pages/add-slider/add-slider.module').then( m => m.AddSliderPageModule)
  },
  {
    path: 'select-data',
    loadChildren: () => import('./pages/select-data/select-data.module').then( m => m.SelectDataPageModule)
  },
  {
    path: 'map',
    loadChildren: () => import('./pages/map/map.module').then( m => m.MapPageModule)
  },
  {
    path: 'send-notification',
    loadChildren: () => import('./pages/send-notification/send-notification.module').then( m => m.SendNotificationPageModule)
  },
  {
    path: 'employee',
    loadChildren: () => import('./pages/employee/employee.module').then( m => m.EmployeePageModule)
  },
  {
    path: 'add-employee',
    loadChildren: () => import('./pages/add-employee/add-employee.module').then( m => m.AddEmployeePageModule)
  },
  {
    path: 'qr-code',
    loadChildren: () => import('./pages/qr-code/qr-code.module').then( m => m.QrCodePageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then( m => m.SettingsPageModule)
  },
  {
    path: 'add-order-type',
    loadChildren: () => import('./pages/add-order-type/add-order-type.module').then( m => m.AddOrderTypePageModule)
  },
  {
    path: 'driver-orders',
    loadChildren: () => import('./pages/driver-orders/driver-orders.module').then( m => m.DriverOrdersPageModule)
  },
  {
    path: 'add-user',
    loadChildren: () => import('./pages/add-user/add-user.module').then( m => m.AddUserPageModule)
  },
  {
    path: 'report',
    loadChildren: () => import('./pages/report/report.module').then( m => m.ReportPageModule)
  },
  {
    path: 'filter',
    loadChildren: () => import('./pages/filter/filter.module').then( m => m.FilterPageModule)
  },
  {
    path: 'colors',
    loadChildren: () => import('./pages/colors/colors.module').then( m => m.ColorsPageModule)
  },
  {
    path: 'multi-images',
    loadChildren: () => import('./pages/multi-images/multi-images.module').then( m => m.MultiImagesPageModule)
  },
  {
    path: 'call-information',
    loadChildren: () => import('./pages/call-information/call-information.module').then( m => m.CallInformationPageModule)
  },
  {
    path: 'country',
    loadChildren: () => import('./pages/country/country.module').then( m => m.CountryPageModule)
  },
  {
    path: 'country-add',
    loadChildren: () => import('./pages/country-add/country-add.module').then( m => m.CountryAddPageModule)
  },
  {
    path: 'sms',
    loadChildren: () => import('./pages/sms/sms.module').then( m => m.SmsPageModule)
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./pages/privacy-policy/privacy-policy.module').then( m => m.PrivacyPolicyPageModule)
  },
  {
    path: 'section',
    loadChildren: () => import('./pages/section/section.module').then( m => m.SectionPageModule)
  },
  {
    path: 'section-add',
    loadChildren: () => import('./pages/section-add/section-add.module').then( m => m.SectionAddPageModule)
  },

  {
    path: 'sub-section',
    loadChildren: () => import('./pages/sub-section/sub-section.module').then( m => m.SubSectionPageModule)
  },
  {
    path: 'sub-section-add',
    loadChildren: () => import('./pages/sub-section-add/sub-section-add.module').then( m => m.SubSectionAddPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

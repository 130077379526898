import { Component } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { StatusBar, Style } from '@capacitor/status-bar';
import { NavController, Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { LocationService } from './services/location/location.service';
import { AuthService } from './services/auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private navCtrl: NavController,
    private platform: Platform,
    private storage:Storage,
    private authService:AuthService
  ) {
    this.initializeApp();
  }

  async initializeApp() {
    await this.storage.create();
    await this.platform.ready()
    await this.setDefaultStatusBar();
    await this.checkUser();
  }

  async checkUser() {
    const user = await this.storage.get('user')
    if(user){
      this.authService.userStatus();
      return this.navCtrl.navigateRoot('/home')
    }
    return this.navCtrl.navigateRoot('/login')
  }

  async setDefaultStatusBar() {
    if(Capacitor.getPlatform() == "web" ) return
    await StatusBar.setStyle({ style: Style.Light })
    if(this.platform.is("android")) {
      await StatusBar.setBackgroundColor({ color: "#FFFFFF" })
      await StatusBar.setOverlaysWebView({ overlay: false });
    }
  }
}

import { Injectable } from '@angular/core';
import { FCM } from '@capacitor-community/fcm';
import { PushNotifications } from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';
import { Howl } from 'howler';
import { ToastController, NavController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { HttpClient } from '@angular/common/http';
import { take } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class FcmService {
  user: any = {};
  isEmployee:boolean = false;
  constructor(
    private toastCtrl: ToastController,
    private navCtrl: NavController,
    private storage: Storage,
    private http:HttpClient
  ) {}

  async initPush() {
    this.isEmployee = localStorage.getItem("employee") == 'true' || false;
    this.user = await this.storage.get('user');
    if (Capacitor.getPlatform() == 'web') return;
    await PushNotifications.requestPermissions();
    await PushNotifications.register();
    this.notifications();
  }

  async notifications() {
    await FCM.subscribeTo({ topic: `admin` })
    if(this.isEmployee) await FCM.subscribeTo({ topic: `employee-${this.user._id}` })

    PushNotifications.addListener(
      'pushNotificationReceived',
      async (notification) => {
        console.log(notification);

        this.presentToast(notification.title, notification.body);
      }
    );
  }


  async unsubscribe() {
    if (Capacitor.getPlatform() == 'web') return;
    await FCM.unsubscribeFrom({ topic: `admin` });
    if(this.user?._id) await FCM.unsubscribeFrom({ topic:'user-' + this.user._id });
  }

  // Toast For Notification
  async presentToast(header: string, message: string) {
    const toast = await this.toastCtrl.create({
      header,
      message,
      position: 'top',
      duration: 3000,
    });
    this.sound();
    await toast.present();
    toast.addEventListener('click', async () => {
      this.navCtrl.navigateForward('/notifications');
      await toast.dismiss();
    });
  }

  // NotificationSound
  sound() {
    const sound = new Howl({
      src: [
        '../../../assets/16451_download_note_iphone_notification_ringtone_apple_sms_ringtones.mp3',
      ],
      volume: 1,
    });

    sound.play();
  }

  postNotification(body:any) {
    this.http.post(`http://209.250.237.58:5006/fcm`,body).pipe(take(1)).subscribe()
  }


}

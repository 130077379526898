import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { from } from 'rxjs';
import { DataService } from '../data/data.service';
import { FunctionsService } from '../functions/functions.service';
import { FcmService } from '../fcm/fcm.service';

const USER = 'user';
const ACCESS_TOKEN = 'accessToken';
const REFRESH_TOKEN = 'refreshToken';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private dataService: DataService,
    private functionService: FunctionsService,
    private storage: Storage,
    private navCtrl: NavController,
    private fcm :FcmService
  ) {}

  getAccessToken() {
    return localStorage.getItem(ACCESS_TOKEN);
  }

  async removeCredentials() {
    localStorage.removeItem(ACCESS_TOKEN);
    return await Promise.all([
      this.storage.remove(USER),
      this.storage.remove(REFRESH_TOKEN),
    ]);
  }

  getRefreshToken() {
    let promise = new Promise(async (resolve, reject) => {
      let token = await this.storage.get(REFRESH_TOKEN);
      this.dataService
        .getData('/user/refreshToken?token=' + token)
        .subscribe(
          (res: any) => {
            localStorage.setItem(ACCESS_TOKEN, res.accessToken);
            resolve(res.token);
          },
          (e) => reject(e)
        );
    });
    return from(promise);
  }


  login(body: any) {
    this.functionService.showLoading();
    this.dataService.postData('/user/login/admin', body).subscribe(
      async (user: any) => {
        await this.storage.set(USER, user);
        await this.storage.set(REFRESH_TOKEN, user.refreshToken);
        localStorage.setItem(ACCESS_TOKEN, user.accessToken);
        localStorage.removeItem("isEmployee")
        this.functionService.dismissLoading();
        this.navCtrl.navigateForward('/home');
      },
      (err) => {
        this.functionService.dismissLoading();
        if (err.status == 404)
          return this.functionService.presentToast(
            'خطأ باسم المستخدم او كلمة المرور'
          );

        if (err.status == 403)
          return this.functionService.presentToast(
            'لقد تم تعطيل حسابك برجاء التواصل مع الشركة'
          );
        return this.functionService.presentToast('خطأ بالشبكة');
      }
    );
  }

  employeeLogin(body:any) {
    this.functionService.showLoading();
    this.dataService.postData('/employee/login', body).subscribe(
      async (user: any) => {
        await this.storage.set(USER, user);
        await this.storage.set(REFRESH_TOKEN, user.refreshToken);
        this.dataService.branches = user?.branches || []
        localStorage.setItem(ACCESS_TOKEN, user.accessToken);
        localStorage.setItem("isEmployee","true")
        this.functionService.dismissLoading();
        this.navCtrl.navigateForward('/home');
      },
      (err) => {
        this.functionService.dismissLoading();
        if (err.status == 404) return this.functionService.presentToast('خطأ باسم المستخدم او كلمة المرور');
        if (err.status == 403) return this.functionService.presentToast('لقد تم تعطيل حسابك برجاء التواصل مع الشركة');
        return this.functionService.presentToast('خطأ بالشبكة');
      }
    );
  }

  employeeRegister(body:any) {
    this.functionService.showLoading();
    this.dataService.postData('/employee/register', body).subscribe(
      async (user: any) => {
        this.functionService.dismissLoading();
        this.navCtrl.pop();
      },
      (err) => {
        this.functionService.dismissLoading();
        if (err.status == 409) return this.functionService.presentToast('اسم المستخدم موجود بالفعل')
        if (err.status == 400) return this.functionService.presentToast('يجب الا تقل كلمة المرور عن 6')
        return this.functionService.presentToast('خطأ بالشبكة');
      }
    );
  }




  async logOut() {
    this.functionService.showLoading();
    await this.fcm.unsubscribe()
    await this.removeCredentials();
    localStorage.removeItem("isEmployee")
    this.functionService.dismissLoading();
    this.navCtrl.navigateRoot('/login');
  }

  userStatus() {
    this.dataService.getData(`/status`).subscribe(async (res: any) => {
      if (res.active) {
        await this.storage.set('user', res?.user);
        return;
      }
      this.functionService.presentToast(res.message);
      this.logOut();
    });
  }
}
